export const ACCOUNT_LEVEL_LIST = [
  { text: "警察機関", value: "1" },
  { text: "学術機関", value: "2" },
  { text: "正会員A", value: "3" },
  { text: "正会員B", value: "4" },
  { text: "特定会員", value: "5" },
  { text: "トライアル会員", value: "6" },
  { text: "賛助会員", value: "7" },
  { text: "事務局", value: "8" },
];

export const ACCOUNT_TYPE_LIST = [
  { text: "警察1", value: "P1" },
  { text: "警察2", value: "P2" },
  { text: "警察3", value: "P3" },
  { text: "警察4", value: "P4" },
  { text: "会員企業/事務局", value: "C" },
  { text: "学術機関", value: "A" },
];
